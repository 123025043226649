import { Platform } from '@canalplus/sdk-core';
import { isResizeMode, isSomeEnum } from '@dce-front/onewebapp-utils';
import '../assets/base_css/base.css';
import type { Locale } from '../constants/lang';
import { Queries } from '../constants/url';
import '../globals';
import { importDiveTokensBranded } from '../helpers/application/application-helper';
import {
  getLocaleData,
  getPublicConfig,
} from '../helpers/config/config-helper';
import {
  refreshProfileOneCore,
  setInitialQueryParams,
} from '../helpers/ifcOneCore/ifc-onecore-helpers';
import { initializeIfc } from '../helpers/ifcOneCore/ifc-onecore.initialize';
import { loadClientTranslations } from '../helpers/lang/lang-helper';
import Logger from '../helpers/logger/logger-helper';
import { markPerformance } from '../helpers/performance/markPerformance';
import { initializeManualSettingsOverride } from '../helpers/settings/settings-helper';
import { sendLaunchAppEvent } from '../helpers/tracking/tracking-helper';
import { getUserAgent } from '../helpers/userAgent/userAgent-helper';
import { initializewaitForPassToken } from '../helpers/waitForPassToken/waitForPassToken-helper';
import {
  getCookieWhoIsWatchingDisabled,
  getCookieWhoIsWatchingSession,
} from '../helpers/whoIsWatching/cookieHelper';
import { performHodorCinematicUniversal } from '../services/Hodor/hodor-services';
import { getIsomorphicStore } from '../store/getIsomorphicStore';
import {
  setApplicationResize,
  setContextInstantiation,
  setForceWhoIsWatching,
  setUserAgent,
  setWhoIsWatchingDisabled,
  setWhoIsWatchingUserSession,
  setZoneInfo,
  showGDPR,
} from '../store/slices/application';
import {
  getFeatureToggleBlueTim,
  getThemeSelector,
  getFeatureToggleDidomi,
} from '../store/slices/application-selectors';
import { isWhoIsWatchingForceDisplay } from '../store/slices/application-types';
import { setupAsTV } from '../store/slices/displayMode';
import { screenResize } from '../store/slices/ui';
import { getPassToken, receiveUserDataClient } from '../store/slices/user';
import { isKidsProfileSelector } from '../store/slices/user-selectors';
import removeServiceWorkers from './components/removeServiceWorkers';

// Sharedcomponent imports, contains css variables
import '@canalplus/mycanal-sharedcomponent/dist/assets/index.css';

// DIVE stylesheets imports:
// - mandatory Fonts declarations
import '@dce-front/dive/dist/css/fonts.css';

// - mandatory Tailwind classes
import '@dce-front/dive/dist/css/components.css';

// - all unbranded dive-tokens variables, including mandatory colors.core.css required by importDiveTokensBranded()
import type { PassUserDataEnriched } from '@canalplus/sdk-pass';
import '@dce-front/dive-tokens/web/css/unbranded.css';

export default async function client(
  userInfos?: PassUserDataEnriched,
  ifcAlreadyInitialized?: boolean,
): Promise<void> {
  console.info('Build Info', {
    // We do not use shorthand here since variable is replaced but webpack
    $_BUILD_RENDERMODE_CSR: $_BUILD_RENDERMODE_CSR,
    $_BUILD_RENDERMODE_SSR: $_BUILD_RENDERMODE_SSR,
    $_BUILD_TARGET_WEB: $_BUILD_TARGET_WEB,
    $_BUILD_TARGET_NODE: $_BUILD_TARGET_NODE,
    $_BUILD_APP_VERSION: $_BUILD_APP_VERSION,
    $_BUILD_APP_GIT_REF: $_BUILD_APP_GIT_REF,
  });

  console.log('dummy log that changes the sha');
  // For playstation we need apply some different CSS values
  // (Example overflow hidden to deactivate native scroll on elements)
  if (navigator.userAgent.toLocaleLowerCase().includes('playstation')) {
    document.documentElement.classList.add('device-playstation');
  }

  const {
    defaultLocale,
    api: {
      hodor: { defaultAppKey },
    },
  } = getPublicConfig();
  const query = new URLSearchParams(window.location.search);
  const isTvDevice = query.get(Queries.Display) === 'tv';
  const offerLocationQuery = query.get(Queries.OfferLocation);
  const offerZoneQuery = query.get(Queries.OfferZone);

  // Init IFC onecore
  if ($_BUILD_RENDERMODE_CSR && !ifcAlreadyInitialized) {
    initializeIfc();
  }

  // This is populated on one-discovery by the buffer page
  const initialHistoryEntries = JSON.parse(
    query.get(Queries.InitialHistoryEntries) || '[]',
  );

  const { store, history } = getIsomorphicStore({
    initialHistoryEntries,
  });
  const state = store.getState();

  markPerformance('start', state);

  // On OD we use the initial history entries provided by buffer page, on web we use
  // location.pathname directly
  const pathnameForLocaleData = $_BUILD_RENDERMODE_CSR
    ? initialHistoryEntries[0]
    : window.location.pathname;

  const locale = getLocaleData(
    isTvDevice,
    pathnameForLocaleData,
    offerLocationQuery,
    offerZoneQuery,
  );
  const { offerLocation, lang, offerZone, appKey } = locale;
  const platformQuery = query.get(Queries.Platform);
  const platform =
    platformQuery && isSomeEnum(Platform)(platformQuery)
      ? platformQuery
      : undefined;
  const resizeModeQuery = query.get(Queries.Resize);
  const resizeMode = isResizeMode(resizeModeQuery)
    ? resizeModeQuery
    : undefined;
  const isFeatDidomi = getFeatureToggleDidomi(state);

  // Save initial search queries
  setInitialQueryParams(window.location.search);

  if ($_BUILD_RENDERMODE_CSR && resizeMode) {
    store.dispatch(setApplicationResize(resizeMode));
  }

  // For CSR mode (like oneDiscovery in pure SPA mode), initialize store with stuff done in SSR
  if ($_BUILD_RENDERMODE_CSR) {
    store.dispatch(setupAsTV());
    if (platform) {
      store.dispatch(setContextInstantiation(platform));
    } else {
      Logger.error(
        `[Client] Set context error : ${platform} is unknown, check the ApplicationContext enum`,
      );

      if (userInfos?.askForConsent && !isFeatDidomi) {
        store.dispatch(showGDPR(true));
      }
    }

    // get whoIsWatching user settings from cookies and store it
    store.dispatch(
      setWhoIsWatchingUserSession(getCookieWhoIsWatchingSession()),
    );
    store.dispatch(setWhoIsWatchingDisabled(getCookieWhoIsWatchingDisabled()));

    store.dispatch(
      setZoneInfo({
        appKey: appKey || defaultAppKey,
        lang: lang || defaultLocale.langKey,
        offerLocation,
        offerZone,
      }),
    );
  }

  // Dispatch an action to test the mobile user agent
  // NOTE this action must be placed before renderSource action
  // because `isMobile` needs to be set with right value
  store.dispatch(setUserAgent(getUserAgent()));

  initializeManualSettingsOverride(store); // At the very start of the function so the manual override can be taken into account

  const theme = getThemeSelector(state);
  const isBlueTim = getFeatureToggleBlueTim(state);

  const applicationNode = document.getElementById('application');

  if (applicationNode === null) {
    throw new Error('No application node found');
  }

  initializewaitForPassToken(store);

  store.dispatch(screenResize(window.innerWidth));

  const rendererOptions = {
    store,
    target: applicationNode,
    history,
    userInfos,
  };

  // In CSR mode we need to manually dispatch userInfos from one-core into state
  if ($_BUILD_RENDERMODE_CSR) {
    if (!userInfos) {
      throw new Error('expected user info to be defined');
    }

    // refresh oneCore profile to update passJSON before rendering the app needed for tracking events after login
    await refreshProfileOneCore({ noCache: true });

    const forceWhoIsWatching = query.get(Queries.ForceWhoIsWatching);
    if (
      typeof forceWhoIsWatching === 'string' &&
      isWhoIsWatchingForceDisplay(forceWhoIsWatching)
    ) {
      store.dispatch(setForceWhoIsWatching(forceWhoIsWatching));
    }

    store.dispatch(getPassToken(userInfos.passToken));
    store.dispatch(receiveUserDataClient(userInfos));

    const [{ default: renderer }, authenticate] = await Promise.all([
      import(/* webpackChunkName: "renderer" */ './components/renderer'),
      performHodorCinematicUniversal({
        store,
      }),
      loadClientTranslations(`${locale.lang}-${offerLocation}` as Locale),
      importDiveTokensBranded(isBlueTim, theme),
    ]);

    // Send launch app event after IFC onecore init only if user is authenticated
    const isKids = isKidsProfileSelector(state);
    if (userInfos.isAuthenticated) {
      sendLaunchAppEvent({ isKids });
    }

    // Show GDPR modal if needed
    if (userInfos?.askForConsent) {
      store.dispatch(showGDPR(true));
    }
    // For tvDevicesWith deeplink we have to initialize an history object with 2 entries : the homepage and the deeplink
    // page so that the user can back to home. However, when initializing the history entries, we do not have the "true" path
    // of the homepage yet (which can be "/", "/pl", or "/home-kids" depending on the context).
    // That's why we rewrite the history entries in a very hacky way after we get the authenticate result
    if (
      initialHistoryEntries.length > 2 &&
      authenticate?.arborescence?.[0]?.onClick?.path
    ) {
      // VERY HACKY SHIT
      (history as any).entries[0].pathname =
        authenticate?.arborescence?.[0].onClick?.path;
    }

    await renderer(rendererOptions);
  } else {
    const [{ default: renderer }] = await Promise.all([
      import(/* webpackChunkName: "renderer" */ './components/renderer'),
      loadClientTranslations(`${locale.lang}-${offerLocation}` as Locale),
      importDiveTokensBranded(isBlueTim, theme),
    ]);
    await renderer(rendererOptions);
  }

  removeServiceWorkers();
}
