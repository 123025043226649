import type { ApiV2InitResponse } from '@dce-front/hodor-types';
import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';
import { getUrlWithQueries } from '../helpers/getUrlWithQueries';

export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  {
    id: 'appKey',
    in: 'path',
  },
  {
    id: 'accept-language',
    in: 'header',
  },
  {
    id: 'tokenPass',
    in: 'header',
  },
  {
    id: 'xx-profile-id',
    in: 'header',
  },
  {
    id: 'offerZone',
    in: 'path',
  },
  {
    id: 'device',
    in: 'path',
  },
  {
    id: 'arboVersion',
    in: 'path',
  },
  {
    id: 'deviceId',
    in: 'parameters',
  },
  {
    id: 'userId',
    in: 'parameters',
  },
];

const API = 'init';

export type InitParameters = {
  allowedProfiles?: 'kids' | 'adult' | 'kids,adult';
  refreshActivationStatus?: boolean;
  refreshProfiles?: boolean;
  featureToggles?: string;
};

/** Fetch Hodor init Api */
export const init = async (
  hodorSdkConfig: HodorSdkConfig,
  initParameters: InitParameters,
): Promise<ApiV2InitResponse> => {
  const {
    baseUrl,
    logger,
    paths: { init: initPath },
  } = hodorSdkConfig;

  const startAt = Date.now();

  const response = await fetchHodor({
    api: API,
    hodorSdkConfig,
    hodorSdkParameters: HODOR_API_PARAMETERS,
    startAt,
    url: getUrlWithQueries(`${baseUrl}${initPath}`, initParameters),
  });

  const jsonResponse = await response.json();

  logger.debug(`HODOR ${API} Response:`, jsonResponse);

  return jsonResponse;
};
