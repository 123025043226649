import type { NumberStringObject } from '../types';

/**
 * Function Helper
 * --------------------
 *
 * **NumberStringObject** type guard.
 *
 * @param object The **object** to be parsed
 *
 * @category Function
 */
const isNumberStringObject = (object: any): object is NumberStringObject =>
  typeof object === 'object' &&
  !Array.isArray(object) &&
  object !== null &&
  !!Object.keys(object).length &&
  Object.keys(object).every(
    (queryKey) =>
      typeof object[queryKey] === 'string' ||
      typeof object[queryKey] === 'number',
  );

export default isNumberStringObject;
