export const PROFILE = 'profile';
export const PROFILES_LIMIT = 10;

export const ProfileModal = {
  AvatarSelectionView: 'AVATAR_SELECTION_VIEW',
  DeleteConfirmationView: 'DELETE_CONFIRMATION_VIEW',
  ProfileCreationView: 'PROFILE_CREATION_VIEW',
  ProfileCreationViewFromLanding: 'PROFILE_CREATION_VIEW_FROM_LANDING',
  ProfileEditionView: 'PROFILE_EDITION_VIEW',
  ProfileListView: 'PROFILE_LIST_VIEW',
} as const;

export type ProfileModal = (typeof ProfileModal)[keyof typeof ProfileModal];
