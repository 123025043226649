import type { Lang } from '../lang';
import type { OfferLocation } from '../offerLocation';
import type { OfferZone } from '../offerZone';
import type { Operator } from '../operator';
import { getAllLocaleZones } from './getAllLocaleZones';
import { getOfferZone } from './getOfferZone';
import type { Zone, ZoneData } from './types';

type ZoneParametersInput = {
  lang?: Lang;
  offerLocation: OfferLocation;
  operator: Operator;
  zones: Zone;
};

export type ZoneParameters = ZoneData & {
  offerLocation: OfferLocation;
  offerZone: OfferZone;
};

/**
 * Get the zone parameters for a given
 * - `OfferLocation`
 * - `Lang`
 * - `Zone`
 * - `Operator`
 *
 * Throws an error if the zone object does not contain localeZone
 */
export const getZoneParameters = ({
  offerLocation,
  lang,
  zones,
  operator,
}: ZoneParametersInput): ZoneParameters => {
  const allLocaleZones = getAllLocaleZones(zones);
  const offerZone = getOfferZone({ offerLocation, operator, lang });
  const localeZone =
    (lang && allLocaleZones[offerLocation]?.[lang]) ||
    allLocaleZones[offerLocation]?.default;

  if (typeof localeZone === 'undefined') {
    throw new Error(
      `CORE getZoneParameters The provided zone object does not contain localeZone data for the offerLocation ${offerLocation}`,
    );
  }

  return {
    ...localeZone,
    offerLocation,
    offerZone,
  };
};
