import { isSomeEnum } from '@dce-front/onewebapp-utils';

export enum Moods {
  Dislike = 'dislike',
  Like = 'like',
  Neutral = 'neutral',
  NoOpinion = 'noopinion',
}

export const isMoods = isSomeEnum(Moods);
