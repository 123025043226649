import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';

export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  {
    id: 'device',
    in: 'path',
  },
  {
    id: 'offerLocation',
    in: 'parameters',
  },
];

export type ConfigurationParameters = {
  version: string;
};

const API = 'configuration.json';

/**
 * Configuration.json hodor API
 */
export async function configuration<T = unknown>(
  hodorSdkConfig: HodorSdkConfig,
  { version }: ConfigurationParameters,
): Promise<T> {
  const { baseUrl, logger, paths } = hodorSdkConfig;

  const response = await fetchHodor({
    api: API,
    hodorSdkConfig,
    hodorSdkParameters: HODOR_API_PARAMETERS,
    startAt: Date.now(),
    // Safeguard to ensure we have a proper URL is {appKey} is filled in baseUrl
    url: `${baseUrl.replace('/{appKey}', '')}${paths.configuration.replace('{version}', version)}`,
  });

  const jsonResponse = await response.json();

  logger.debug(`HODOR ${API} Response:`, jsonResponse);

  return jsonResponse;
}
