export enum DownloadToGoTypeKeys {
  TRACK_OPEN_DOWNLOAD_TO_GO = 'OPEN_DOWNLOAD_TO_GO',
}

export interface ID2GTrackOpenModal {
  readonly type: DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO;
}
export const trackOpenDownloadToGo = (): ID2GTrackOpenModal => ({
  type: DownloadToGoTypeKeys.TRACK_OPEN_DOWNLOAD_TO_GO,
});

export type DownloadToGoActionTypes = ID2GTrackOpenModal;
