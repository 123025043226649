export const ResizeMode = {
  RESIZE_720P: '720p',
  RESIZE_AUTO: 'auto',
} as const;

export type ResizeMode = (typeof ResizeMode)[keyof typeof ResizeMode];

// if you change it, you have to change the string in css too
export const ResizeViewportMapping = {
  [ResizeMode.RESIZE_720P]: 'resize resize720p',
};
