import { rpc, type RPCResponse } from '@canalplus/ifc';
import type { OneCoreEventTypes } from '@canalplus/onecore-types';
import type { StreamParam, StreamsManager } from './types';

export const formatStreamParams = (type: string): StreamParam => {
  const m = type.match(/(\w+):(\w+)/);
  return m && m[1] && m[2] ? { source: m[1], event: m[2] } : type;
};

/**
 * To manage event streams listeners.
 * It returns addStreamListener, removeStreamListener and onMessage receiver
 */
export const getStreamsManager = (): StreamsManager => {
  const streams: Record<string, ((result?: any) => void)[]> = {};

  const addStreamListener = async <
    A extends keyof OneCoreEventTypes,
    R extends OneCoreEventTypes,
  >(
    type: `${A}`,
    listener: (result?: R[A]) => void,
  ) => {
    const typeFormatted = `stream:${type}`;

    if (!streams[typeFormatted]) {
      streams[typeFormatted] = [];
    }
    streams[typeFormatted].push(listener);

    // send addStreamListener only if is the first callback.
    // It's to not call addStreamListener multiple time
    if (streams[typeFormatted].length === 1) {
      await rpc<StreamParam, void>(
        'addStreamListener',
        formatStreamParams(type),
      );
    }
  };

  const removeStreamListener = async <
    A extends keyof OneCoreEventTypes,
    R extends OneCoreEventTypes,
  >(
    type: `${A}`,
    listener: (result?: R[A]) => void,
  ) => {
    const typeFormatted = `stream:${type}`;

    if (!streams[typeFormatted] || streams[typeFormatted].length === 0) {
      return;
    }

    const indexToDelete = streams[typeFormatted].findIndex((callbackArg) => {
      return callbackArg === listener;
    });

    if (indexToDelete !== -1) {
      streams[typeFormatted].splice(indexToDelete, 1);
    }
  };

  const onMessage = (data: RPCResponse) => {
    // stream event doesn't have id
    if (data.id) {
      return;
    }

    /* Check event stream
     A event stream is sent with a key in data corresponding of the type of the event.
     Example of data :
     {
       jsonrpc: '2.0',
       id: undefined,
       'stream:application:focus': true 
     }
    */
    Object.keys(data).forEach((key) => {
      if (streams[key]) {
        streams[key].forEach((callbackArg) => {
          callbackArg(data[key]);
        });
      }
    });
  };

  return { addStreamListener, removeStreamListener, onMessage };
};
