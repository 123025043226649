import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const Environment = {
  DEV: 'development',
  REC: 'recette',
  PREPROD: 'preproduction',
  PROD: 'production',
} as const;

export type Environment = (typeof Environment)[keyof typeof Environment];

/** {@link @canalplus/sdk-core!Environment} type guard */
export const isEnvironment = isSomeEnum(Environment);
