/**
 * URL Helper
 * --------------------
 *
 * Transform the given object parameters into an encoded query string
 *
 * @param object The **object** to transform
 * @param array The **exceptions** contains the key of the queries not to be encoded
 * @returns The resulting **encoded query string**
 *
 * @category Url
 * @example
 * getEncodedQueryString({ test: 'test', value: 'value' })
 * // => '?test=test&value=value'
 */
export const getEncodedQueryString = (
  object: { [T: string]: string | boolean | number },
  exceptions?: string[],
): string => {
  const queries = Object.keys(object).reduce((acc: string[], key): string[] => {
    const value = object[key];

    if (value === null || value === undefined) {
      return acc;
    }

    acc.push(
      exceptions && exceptions.includes(key)
        ? `${key}=${value.toString()}`
        : `${key}=${encodeURIComponent(value)}`,
    );

    return acc;
  }, []);

  return queries.length ? `?${queries.join('&')}` : '';
};
