export const paths = {
  activateAuthenticationToken:
    '/provider/services/{zone}/public/activateAuthenticationToken',
  createToken: '/provider/services/{zone}/public/createToken',
  generateAuthenticationToken:
    '/provider/services/{zone}/public/generateAuthenticationToken',
  getAccessToken: '/provider-IdPOAuth2/token/PROVIDER',
  getUserInfoWithAccessToken: '/provider-IdPOAuth2/api/PROVIDER',
  healthCheck: '/provider/services/public/version',
  login: '/provider-IdPOAuth2/auth/PROVIDER',
  loginByAuthenticationToken:
    '/provider/services/{zone}/public/loginByAuthenticationToken',
  logout: '/provider/logout',
  registerAndLoginEquipment:
    '/provider/services/public/registerAndLoginEquipment',
  signup: '/service/oauth2/subscribe',
  updateUserConsent: '/provider/services/{zone}/public/updateUserConsent',
} as const;
