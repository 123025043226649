import { isSomeEnum } from '@dce-front/onewebapp-utils';

/**
 * All sdk platforms.\
 * Note that this is a sdk enum mean to be independent from one-core, hodor, ect...
 */
export const Platform = {
  FireTV: 'FireTV',
  G11: 'G11',
  G10: 'G10',
  G10LCPI: 'G10LCPI',
  G9: 'G9',
  G9LCPI: 'G9LCPI',
  G9LCPISat: 'G9LCPISat',
  G9Mini: 'G9Mini',
  G9Sat: 'G9Sat',
  // poland specific GLine devices
  G9LBis: 'G9LBis',
  G9LNCP: 'G9LNCP',
  G9SE: 'G9SE',
  Hisense: 'Hisense',
  LG: 'LG',
  OrangeKey: 'OrangeKey',
  OrangeManhattan: 'OrangeManhattan',
  OrangeMib4: 'OrangeMib4',
  OrangePlay: 'OrangePlay',
  OrangeStellar: 'OrangeStellar',
  Philips: 'Philips',
  PlayStation4: 'PlayStation4',
  PlayStation5: 'PlayStation5',
  Samsung: 'Samsung',
  SFR7IPTV: 'SFR7IPTV',
  SFR8IPTV: 'SFR8IPTV',
  SFR8Cable: 'SFR8Cable',
  Web: 'Web',
} as const;

/**
 * All sdk platforms type
 */
export type Platform = (typeof Platform)[keyof typeof Platform];

/**
 * Test if a value is a {@link Platform}
 * @param value Value to test
 * @returns True if the value is a Platform
 */
export function isPlatform(value?: string): value is Platform {
  return isSomeEnum(Platform)(value);
}

/**
 * All sdk platform groups
 * Note that this is a sdk enum mean to be independent from one-core, hodor, ect...
 */
export const PlatformGroup = {
  FireTV: 'FireTV',
  GLine: 'GLine',
  Hisense: 'Hisense',
  LG: 'LG',
  Orange: 'Orange',
  Philips: 'Philips',
  PlayStation: 'PlayStation',
  Samsung: 'Samsung',
  SFR: 'SFR',
  Web: 'Web',
} as const;

/**
 * All sdk platform groups type
 */
export type PlatformGroup = (typeof PlatformGroup)[keyof typeof PlatformGroup];

/**
 * Test if a value is a {@link PlatformGroup}
 * @param value Value to test
 * @returns True if the value is a PlatformGroup
 */
export function isPlatformGroup(value?: string): value is PlatformGroup {
  return isSomeEnum(PlatformGroup)(value);
}

/**
 * Maps a {@link Platform} to a {@link PlatformGroup}
 */
export const PlatformGroupMap = {
  FireTV: PlatformGroup.FireTV,
  G11: PlatformGroup.GLine,
  G10: PlatformGroup.GLine,
  G10LCPI: PlatformGroup.GLine,
  G9: PlatformGroup.GLine,
  G9LCPI: PlatformGroup.GLine,
  G9LCPISat: PlatformGroup.GLine,
  G9Mini: PlatformGroup.GLine,
  G9Sat: PlatformGroup.GLine,
  // poland specific GLine devices
  G9LBis: PlatformGroup.GLine,
  G9LNCP: PlatformGroup.GLine,
  G9SE: PlatformGroup.GLine,
  Hisense: PlatformGroup.Hisense,
  LG: PlatformGroup.LG,
  OrangeKey: PlatformGroup.Orange,
  OrangeManhattan: PlatformGroup.Orange,
  OrangeMib4: PlatformGroup.Orange,
  OrangePlay: PlatformGroup.Orange,
  OrangeStellar: PlatformGroup.Orange,
  Philips: PlatformGroup.Philips,
  PlayStation4: PlatformGroup.PlayStation,
  PlayStation5: PlatformGroup.PlayStation,
  Samsung: PlatformGroup.Samsung,
  SFR7IPTV: PlatformGroup.SFR,
  SFR8IPTV: PlatformGroup.SFR,
  SFR8Cable: PlatformGroup.SFR,
  Web: PlatformGroup.Web,
} as const satisfies Record<Platform, PlatformGroup>;

/**
 * All Hodor platforms.\
 * Map an sdk platform to a hodor platform
 *
 * @example HodorPlatform[Platform.LG] // "tvlg"
 *
 * @see {@link Platform}
 */
export const HodorPlatform = {
  [Platform.FireTV]: 'firetvreact',
  [Platform.G11]: 'g11',
  [Platform.G10]: 'g10',
  [Platform.G10LCPI]: 'g10lcpi',
  [Platform.G9]: 'g9',
  [Platform.G9LCPI]: 'g9lcpi',
  [Platform.G9LCPISat]: 'g9lcpi',
  [Platform.G9Mini]: 'g9',
  [Platform.G9Sat]: 'g9',
  // Poland-specific GLine devices
  // FIXME: this is default values pending integration changes
  [Platform.G9LBis]: 'g9',
  [Platform.G9LNCP]: 'g9',
  [Platform.G9SE]: 'g9',
  [Platform.Hisense]: 'tvhisense',
  [Platform.LG]: 'tvlg',
  [Platform.OrangeKey]: 'orangekey',
  [Platform.OrangeManhattan]: 'orangemanhattan',
  [Platform.OrangeMib4]: 'orangemib4',
  [Platform.OrangePlay]: 'orangeplay',
  [Platform.OrangeStellar]: 'orangestellar',
  [Platform.Philips]: 'philipstv',
  [Platform.PlayStation4]: 'playstation',
  [Platform.PlayStation5]: 'playstation',
  [Platform.Samsung]: 'newsamsung',
  [Platform.SFR7IPTV]: 'sfrwau',
  [Platform.SFR8IPTV]: 'sfrwau',
  [Platform.SFR8Cable]: 'sfrwau',
  [Platform.Web]: 'webapp',
} as const satisfies Record<Platform, string>;

/**
 * All hodor platform types
 */
export type HodorPlatform = (typeof HodorPlatform)[keyof typeof HodorPlatform];

/**
 * Test if a value is an {@link HodorPlatform}
 * @param value Value to test
 * @returns True if the value is an HodorPlatform
 */
export function isHodorPlatform(value?: string): value is HodorPlatform {
  return isSomeEnum(HodorPlatform)(value);
}

/**
 * All oneCore platforms.\
 * Map an sdk platform to a oneCore platform
 *
 * @example OneCorePlatform[Platform.LG] // "lgtv"
 *
 * @see {@link Platform}
 */
export const OneCorePlatform = {
  [Platform.FireTV]: 'firetvreact',
  [Platform.G11]: 'g11',
  [Platform.G10]: 'g10',
  [Platform.G10LCPI]: 'g10lcpi',
  [Platform.G9]: 'g9',
  [Platform.G9LCPI]: 'g9lcpi',
  [Platform.G9LCPISat]: 'g9lcpisat',
  [Platform.G9Mini]: 'g9mini',
  [Platform.G9Sat]: 'g9sat',
  // Poland-specific GLine devices
  [Platform.G9LBis]: 'g9lbis',
  [Platform.G9LNCP]: 'g9lncp',
  [Platform.G9SE]: 'g9se',
  [Platform.Hisense]: 'hisensetv',
  [Platform.LG]: 'lgtv',
  [Platform.OrangeKey]: 'orangekey',
  [Platform.OrangeManhattan]: 'orangemanhattan',
  [Platform.OrangeMib4]: 'orangemib4',
  [Platform.OrangePlay]: 'orangeplay',
  [Platform.OrangeStellar]: 'orangestellar',
  [Platform.Philips]: 'philipstv',
  [Platform.PlayStation4]: 'playstation4',
  [Platform.PlayStation5]: 'playstation5',
  [Platform.Samsung]: 'samsungtv',
  [Platform.SFR7IPTV]: 'sfrstb7iptv',
  [Platform.SFR8IPTV]: 'sfrstb8iptv',
  [Platform.SFR8Cable]: 'sfrstb8cable',
  [Platform.Web]: 'webapp',
} as const satisfies Record<Platform, string>;

/**
 * All oneCore platform types
 */
export type OneCorePlatform =
  (typeof OneCorePlatform)[keyof typeof OneCorePlatform];

/**
 * Test if a value is an {@link OneCorePlatform}
 * @param value Value to test
 * @returns True if the value is an OneCorePlatform
 */
export function isOneCorePlatform(value?: string): value is OneCorePlatform {
  return isSomeEnum(OneCorePlatform)(value);
}

const OneCorePlatformMap: Record<OneCorePlatform, Platform> = {
  firetvreact: Platform.FireTV,
  g11: Platform.G11,
  g10: Platform.G10,
  g10lcpi: Platform.G10LCPI,
  g9: Platform.G9,
  g9lcpi: Platform.G9LCPI,
  g9lcpisat: Platform.G9LCPISat,
  g9mini: Platform.G9Mini,
  g9sat: Platform.G9Sat,
  // Poland-specific GLine devices
  g9lbis: Platform.G9LBis,
  g9lncp: Platform.G9LNCP,
  g9se: Platform.G9SE,
  hisensetv: Platform.Hisense,
  lgtv: Platform.LG,
  orangekey: Platform.OrangeKey,
  orangemanhattan: Platform.OrangeManhattan,
  orangemib4: Platform.OrangeMib4,
  orangeplay: Platform.OrangePlay,
  orangestellar: Platform.OrangeStellar,
  philipstv: Platform.Philips,
  playstation4: Platform.PlayStation4,
  playstation5: Platform.PlayStation5,
  samsungtv: Platform.Samsung,
  sfrstb7iptv: Platform.SFR7IPTV,
  sfrstb8iptv: Platform.SFR8IPTV,
  sfrstb8cable: Platform.SFR8Cable,
  webapp: Platform.Web,
};

/**
 * transform oneCorePlatform to platfom
 * @param oneCorePlatform value of oneCorePlatform
 * @returns Platform
 */
export function getPlatformFromOneCorePlatform(
  oneCorePlatform: OneCorePlatform,
): Platform {
  return OneCorePlatformMap[oneCorePlatform];
}

/**
 * transform platform to platfomGroup
 * @param platform value of platform
 * @returns PlatfomGroup
 */
export function getPlatformGroupFromPlatform(
  platform: Platform,
): PlatformGroup {
  return PlatformGroupMap[platform];
}

/**
 * All Pass platforms.\
 * Map an sdk platform to a Pass platform
 *
 * ⚠️ `web` is used as a default value
 *
 * @example PassPlatform[Platform.LG] // "webos"
 *
 * @see {@link Platform}
 */
export const PassPlatform = {
  [Platform.FireTV]: 'firetvreact',
  /** ⚠️ default value */
  [Platform.G11]: 'web',
  /** ⚠️ default value */
  [Platform.G10]: 'web',
  /** ⚠️ default value */
  [Platform.G10LCPI]: 'web',
  /** ⚠️ default value */
  [Platform.G9]: 'web',
  /** ⚠️ default value */
  [Platform.G9LCPI]: 'web',
  /** ⚠️ default value */
  [Platform.G9LCPISat]: 'web',
  /** ⚠️ default value */
  [Platform.G9Mini]: 'web',
  /** ⚠️ default value */
  [Platform.G9Sat]: 'web',
  // Poland-specific GLine devices
  /** ⚠️ default value */
  [Platform.G9LBis]: 'web',
  /** ⚠️ default value */
  [Platform.G9LNCP]: 'web',
  /** ⚠️ default value */
  [Platform.G9SE]: 'web',
  [Platform.Hisense]: 'vidaa',
  [Platform.LG]: 'webos',
  [Platform.OrangeKey]: 'ORANGECLETV',
  [Platform.OrangeManhattan]: 'web',
  [Platform.OrangeMib4]: 'web',
  [Platform.OrangePlay]: 'web',
  [Platform.OrangeStellar]: 'web',
  [Platform.Philips]: 'titan',
  [Platform.PlayStation4]: 'playstation',
  [Platform.PlayStation5]: 'playstation',
  [Platform.Samsung]: 'tizen',
  [Platform.SFR7IPTV]: 'SFR',
  [Platform.SFR8IPTV]: 'SFR',
  [Platform.SFR8Cable]: 'SFR',
  [Platform.Web]: 'web',
} as const satisfies Record<Platform, string>;

/**
 * All Pass platform types
 */
export type PassPlatform = (typeof PassPlatform)[keyof typeof PassPlatform];

/**
 * Test if a value is an {@link PassPlatform}
 * @param value Value to test
 * @returns True if the value is an PassPlatform
 */
export function isPassPlatform(value?: string): value is PassPlatform {
  return isSomeEnum(PassPlatform)(value);
}

/**
 * All Hapi platforms.\
 * Map an sdk platform to a Hapi platform
 *
 * ⚠️ `tvsamsung` is used as a default value
 *
 * @example HapiPlatform[Platform.LG] // "tvlg"
 *
 * @see {@link Platform}
 */
export const HapiPlatform = {
  /** ⚠️ default value */
  [Platform.FireTV]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G11]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G10]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G10LCPI]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9LCPI]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9LCPISat]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9Mini]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9Sat]: 'tvsamsung',
  // Poland-specific GLine devices
  /** ⚠️ default value */
  [Platform.G9LBis]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9LNCP]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.G9SE]: 'tvsamsung',
  [Platform.Hisense]: 'tvhisense',
  [Platform.LG]: 'tvlg',
  /** ⚠️ default value */
  [Platform.OrangeKey]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.OrangeManhattan]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.OrangeMib4]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.OrangePlay]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.OrangeStellar]: 'tvsamsung',
  [Platform.Philips]: 'tvphilips',
  [Platform.PlayStation4]: 'playstation',
  [Platform.PlayStation5]: 'playstation',
  [Platform.Samsung]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.SFR7IPTV]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.SFR8IPTV]: 'tvsamsung',
  /** ⚠️ default value */
  [Platform.SFR8Cable]: 'tvsamsung',
  [Platform.Web]: 'pc',
} as const satisfies Record<Platform, string>;

/**
 * All Hapi platform types
 */
export type HapiPlatform = (typeof HapiPlatform)[keyof typeof HapiPlatform];

/**
 * Test if a value is an {@link HapiPlatform}
 * @param value Value to test
 * @returns True if the value is an HapiPlatform
 */
export function isHapiPlatform(value?: string): value is HapiPlatform {
  return isSomeEnum(HapiPlatform)(value);
}

/**
 * All Turbo platforms.\
 * Map an sdk platform to a Turbo platform
 *
 * ⚠️ `web` is used as a default value
 *
 * @example TurboPlatform[Platform.LG] // "tvlg"
 *
 * @see {@link Platform}
 */
export const TurboPlatform = {
  /** ⚠️ default value */
  [Platform.FireTV]: 'web',
  /** ⚠️ default value */
  [Platform.G11]: 'web',
  /** ⚠️ default value */
  [Platform.G10]: 'web',
  /** ⚠️ default value */
  [Platform.G10LCPI]: 'web',
  /** ⚠️ default value */
  [Platform.G9]: 'web',
  /** ⚠️ default value */
  [Platform.G9LCPI]: 'web',
  /** ⚠️ default value */
  [Platform.G9LCPISat]: 'web',
  /** ⚠️ default value */
  [Platform.G9Mini]: 'web',
  /** ⚠️ default value */
  [Platform.G9Sat]: 'web',
  // Poland-specific GLine devices
  /** ⚠️ default value */
  [Platform.G9LBis]: 'web',
  /** ⚠️ default value */
  [Platform.G9LNCP]: 'web',
  /** ⚠️ default value */
  [Platform.G9SE]: 'web',
  [Platform.Hisense]: 'vidaa',
  [Platform.LG]: 'webos',
  /** ⚠️ default value */
  [Platform.OrangeKey]: 'web',
  /** ⚠️ default value */
  [Platform.OrangeManhattan]: 'web',
  /** ⚠️ default value */
  [Platform.OrangeMib4]: 'web',
  /** ⚠️ default value */
  [Platform.OrangePlay]: 'web',
  /** ⚠️ default value */
  [Platform.OrangeStellar]: 'web',
  [Platform.Philips]: 'titan',
  [Platform.PlayStation4]: 'playstation',
  [Platform.PlayStation5]: 'playstation',
  [Platform.Samsung]: 'tizen',
  /** ⚠️ default value */
  [Platform.SFR7IPTV]: 'web',
  /** ⚠️ default value */
  [Platform.SFR8IPTV]: 'web',
  /** ⚠️ default value */
  [Platform.SFR8Cable]: 'web',
  [Platform.Web]: 'web',
} as const satisfies Record<Platform, string>;

/**
 * All Turbo platform types
 */
export type TurboPlatform = (typeof TurboPlatform)[keyof typeof TurboPlatform];

/**
 * Test if a value is an {@link TurboPlatform}
 * @param value Value to test
 * @returns True if the value is an TurboPlatform
 */
export function isTurboPlatform(value?: string): value is TurboPlatform {
  return isSomeEnum(TurboPlatform)(value);
}

/**
 * All OnePlayer platforms.\
 * Map an sdk platform to a OnePlayer platform
 *
 * ⚠️ `mycanal` is used as a default value
 *
 * @example OnePlayerPlatform[Platform.LG] // "lg"
 *
 * @see {@link Platform}
 */
export const OnePlayerPlatform = {
  /** ⚠️ default value */
  [Platform.FireTV]: 'mycanal',
  [Platform.G11]: 'g11',
  [Platform.G10]: 'g10',
  [Platform.G10LCPI]: 'g10',
  [Platform.G9]: 'g9',
  [Platform.G9LCPI]: 'g9',
  [Platform.G9LCPISat]: 'g9',
  [Platform.G9Mini]: 'g9mini',
  [Platform.G9Sat]: 'g9',
  // Poland-specific GLine devices
  [Platform.G9LBis]: 'g9',
  [Platform.G9LNCP]: 'g9',
  [Platform.G9SE]: 'g9',
  [Platform.Hisense]: 'hisense',
  [Platform.LG]: 'lg',
  [Platform.OrangeKey]: 'orange',
  [Platform.OrangeManhattan]: 'orange',
  [Platform.OrangeMib4]: 'orange',
  [Platform.OrangePlay]: 'orange',
  [Platform.OrangeStellar]: 'orange',
  [Platform.Philips]: 'philips',
  [Platform.PlayStation4]: 'playstation',
  [Platform.PlayStation5]: 'playstation',
  [Platform.Samsung]: 'samsung',
  /** ⚠️ default value */
  [Platform.SFR7IPTV]: 'mycanal',
  /** ⚠️ default value */
  [Platform.SFR8IPTV]: 'mycanal',
  /** ⚠️ default value */
  [Platform.SFR8Cable]: 'mycanal',
  [Platform.Web]: 'mycanal',
} as const satisfies Record<Platform, string>;

/**
 * All Turbo platform types
 */
export type OnePlayerPlatform =
  (typeof OnePlayerPlatform)[keyof typeof OnePlayerPlatform];

/**
 * Test if a value is an {@link OnePlayerPlatform}
 * @param value Value to test
 * @returns True if the value is an OnePlayerPlatform
 */
export function isOnePlayerPlatform(
  value?: string,
): value is OnePlayerPlatform {
  return isSomeEnum(OnePlayerPlatform)(value);
}
