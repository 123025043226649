import type { StringMap } from '../types';

/**
 * Fill a template with a {@link StringMap}
 * @return the filled template
 * @example
 * fillTemplate(
 *   '{value} is a test, but {test} is a value, no {confusion}',
 *   [
 *     ['value', 'test'],
 *     ['test', 'value'],
 *     ['confusion', 'doubt'],
 *   ]
 * ); // test is a test, but value is a value, no doubt
 */
export const fillTemplate = <T extends string>(
  /** the template to parse */
  template: T,
  /** the {@link StringMap} data used to fill the placeholders */
  replacements: StringMap,
): T => {
  return replacements.reduce((accumulator, replacement) => {
    const [placeholder, replaceValue] = replacement;
    const placeholderRegExp = new RegExp(`{${placeholder}}`, 'g');
    return accumulator.replace(placeholderRegExp, replaceValue) as T;
  }, template);
};
