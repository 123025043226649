import {
  isInitialized as isIfcInitialized,
  setUserConfig,
} from '@canalplus/ifc-onecore';
import { isClientSide } from '@dce-front/onewebapp-utils';
import {
  getFeaturePlayNextEpisode,
  getFeatureToggleHdr,
  getFeatureToggleLiveQuality,
  getFeatureToggleLowLatency,
} from '../slices/application-selectors';
import {
  receiveUserDataClient,
  receiveUserDataServer,
  userChangeSettings,
  userListProfile,
} from '../slices/user';
import {
  isHDRSelector,
  isKidsProfileSelector,
  isLowLatencySelector,
  isNextEpisodeAutoplaySelector,
  liveQualitySelector,
  profileIdSelector,
  profileImgSelector,
} from '../slices/user-selectors';

function getOneCoreHDRValue(value?: boolean): 'on' | 'off' | 'auto' {
  switch (value) {
    case true:
      return 'on';
    case false:
      return 'off';
    default:
      return 'auto';
  }
}
/**
 * Reponsible for synchronisation between our state and one-core
 */
export const oneCoreSyncMiddleware: Redux.Middleware = (store) => {
  let hasSetUserConfigOnce = false;
  let hasSetHdrOnce = false;
  let hasSetLowLatencyOnce = false;
  let hasSetIsNextEpisodeAutoplayOnce = false;
  let hasSetLiveQualityOnce = false;

  return (next) => (action) => {
    const prevState = store.getState();

    next(action);

    const state = store.getState();

    // Early skip
    if (!isClientSide() || !isIfcInitialized() || !$_BUILD_RENDERMODE_CSR) {
      return;
    }

    // Handle profile switch to update one-core status
    if (
      receiveUserDataClient.match(action) ||
      receiveUserDataServer.match(action) ||
      userListProfile.match(action)
    ) {
      const currentProfileId = profileIdSelector(state);
      const currentProfileImage = profileImgSelector(state);
      const currentProfileIsKids = isKidsProfileSelector(state);

      if (
        !hasSetUserConfigOnce ||
        currentProfileId !== profileIdSelector(prevState) ||
        currentProfileImage !== profileImgSelector(prevState) ||
        currentProfileIsKids !== isKidsProfileSelector(prevState)
      ) {
        setUserConfig({
          currentProfileId,
          currentProfileImage,
          currentProfileIsKids,
        });

        hasSetUserConfigOnce = true;
      }

      return;
    }

    // Handle settings that needs to be known by one-core
    if (userChangeSettings.match(action)) {
      if (getFeaturePlayNextEpisode(state)) {
        const prevIsNextEpisodeAutoplayValue =
          isNextEpisodeAutoplaySelector(prevState);
        const nextIsNextEpisodeAutoplayValue =
          isNextEpisodeAutoplaySelector(state);

        if (
          prevIsNextEpisodeAutoplayValue !== nextIsNextEpisodeAutoplayValue ||
          !hasSetIsNextEpisodeAutoplayOnce
        ) {
          setUserConfig({
            playNextEpisode: nextIsNextEpisodeAutoplayValue,
          });

          hasSetIsNextEpisodeAutoplayOnce = true;
        }
      }

      if (getFeatureToggleHdr(state)) {
        const prevHdrValue = getOneCoreHDRValue(isHDRSelector(prevState));
        const nextHdrValue = getOneCoreHDRValue(isHDRSelector(state));

        if (prevHdrValue !== nextHdrValue || !hasSetHdrOnce) {
          setUserConfig({
            hdr: nextHdrValue,
          });

          hasSetHdrOnce = true;
        }
      }

      if (getFeatureToggleLiveQuality(state)) {
        const prevLiveQualityValue = liveQualitySelector(prevState);
        const nextLiveQualityValue = liveQualitySelector(state);

        if (
          prevLiveQualityValue !== nextLiveQualityValue ||
          !hasSetLiveQualityOnce
        ) {
          setUserConfig({
            liveQuality: nextLiveQualityValue,
          });

          hasSetLiveQualityOnce = true;
        }
      }

      if (getFeatureToggleLowLatency(state)) {
        const prevLowLatencyValue = isLowLatencySelector(prevState);
        const nextLowLatencyValue = isLowLatencySelector(state);

        if (
          prevLowLatencyValue !== nextLowLatencyValue ||
          !hasSetLowLatencyOnce
        ) {
          setUserConfig({
            lowLatency: nextLowLatencyValue,
          });

          hasSetLowLatencyOnce = true;
        }
      }

      return;
    }
  };
};
