import { isSomeEnum } from '@dce-front/onewebapp-utils';

export enum SecondaryActionType {
  Download = 'download',
  Playlist = 'playlist',
  Opinion = 'opinion',
  Share = 'share',
  Recording = 'recording',
  Authentication = 'authentication',
}

export const isSecondaryActionType = isSomeEnum(SecondaryActionType);
