import type { Request } from 'express';
import type { ServerResponse } from 'http';
import type { Service } from '../../constants';
import { generateMetadata } from './generateMetadata';

export type LogApiMetadata = {
  '0'?: any;
  '1'?: any;
  '2'?: any;
  '3'?: any;
  '4'?: any;
  accessToken?: string;
  authenticateUrl?: string;
  cmsToken?: string;
  endpoint?: string;
  errorCode?: string | number;
  errorMessage?: string;
  errorStack?: string;
  media?: string;
  offerLocation?: string;
  offerZone?: string;
  operator?: string;
  passId?: string;
  pathName?: string;
  portailId?: string;
  redirect?: string;
  req?: {
    appData?: { appKey?: string; appKeyLog?: string; offerLocation?: string };
    requestId?: string;
    size?: number;
    ssrDuration?: number;
    userData?: { offerZone?: string };
  } & Partial<Request>;
  requestId?: string;
  res?: ServerResponse;
  responseTime?: number;
  route?: string;
  service?: Service;
  stack?: string;
  startAt?: number;
  statusCode?: number;
  url?: string;
  userStatus?: string;
  webservice?: string;
};

/**
 * Generate a loggable metadata object in an API call context
 *
 * @param service The API name (Tms, Pass...)
 * @param route The API route called (createToken, wsFromPath, ...)
 */
export const generateApiMetadata = (
  service: Service,
  route: string,
  data: LogApiMetadata,
): { meta: LogApiMetadata } =>
  generateMetadata(
    Object.assign(data, {
      service,
      webservice: route,
    }),
  );
