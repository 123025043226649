export const DisplayContext = {
  EMBED_PLAYER: 'embed',
  IFRAME: 'iframe',
  TV: 'tv',
  WEBVIEW: 'webview',
} as const;

export type DisplayContext =
  (typeof DisplayContext)[keyof typeof DisplayContext];

export const DisplayMode = {
  FULLSCREEN: 'fullscreen',
  FULLWINDOWED: 'fullwindowed',
  MODAL: 'modal',
  WINDOWED: 'windowed',
} as const;

export type DisplayMode = (typeof DisplayMode)[keyof typeof DisplayMode];
