/**
 * Return the promise with errors timeout catched.
 * Onecore not send always a response when a call is ok, it's to avoid have errors in console for these calls
 * @param promise Promise to catch error timeout
 */
export const catchErrorTimeout = (promise: Promise<void>): Promise<void> => {
  return promise.catch((error: Error) => {
    if (error.message.toLowerCase().includes('timeout')) {
      return;
    }
    throw error;
  });
};
