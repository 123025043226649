import { getEncodedQueryString } from '@dce-front/onewebapp-utils';
import type { PassSdkConfig } from '../config/types';
import { paths } from '../constants';
import { PassError } from '../errors/PassError';
import { fetchPass } from '../helpers/private/fetchPass';
import { getFilledPath } from '../helpers/private/getFilledPath';
import { getPassIdType } from '../helpers/private/getPassIdType';
import type { PassUserData } from '../types/PassUserData';

const api = 'createToken';
const route = 'createToken';

export type CreateTokenParameters = {
  /**
   * International P@SS identifier token (prov_passid) or idp okta sessionToken when passIdType=okta
   *
   * - pass example: FR=AAAAELsH6mxkfN6VxFZGvDNwR24qnNf...
   * - okta example: 20111q26Ry8oR0hQcL4WuMwJosCno...
   */
  passId?: string;

  /** If yes, PASS gets the user rights preferably from the aboprofile service */
  noCache?: boolean;
} & Partial<
  Pick<PassUserData, 'trackingPub' | 'analytics' | 'anonymousTracking'>
>;

export type CreateTokenResponse = {
  passId: string;
  passToken: string;
  userData: PassUserData;
};

/**
 * Authenticates user on P@SS Provider from the P@SS identifier token (prov_passid)\
 * or okta sessionToken and returns the P@SS consumer token and user information.
 *
 * @see {@link https://canal-plus.atlassian.net/wiki/spaces/PASS/pages/161841958/createToken+public createToken API specifications}
 */
export const createToken = async (
  passSdkConfig: PassSdkConfig,
  parameters: CreateTokenParameters,
): Promise<CreateTokenResponse> => {
  const { baseUrl, offerZone, offerLocation, portailId, media, logger, vect } =
    passSdkConfig;

  const url = `${baseUrl.pass}${getFilledPath({
    offerLocation,
    offerZone,
    path: paths.createToken,
  })}`;

  const body = getEncodedQueryString({
    portailId,
    media,
    vect,
    passIdType: getPassIdType(parameters.passId || ''),
    ...parameters,
  }).substring(1);

  logger.debug(`PASS ${api} body: ${body}`);

  const response = await fetchPass({
    api,
    passSdkConfig: {
      ...passSdkConfig,
      fetchOptions: {
        ...passSdkConfig.fetchOptions,
        method: 'POST',
        headers: {
          ...passSdkConfig.fetchOptions?.headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      },
    },
    route,
    url,
  });

  const content = await response.json();

  logger.debug(`PASS ${api} Response:`, content);

  if (content.response.errorCode !== 0) {
    throw new PassError(
      `PASS ${api} Error`,
      response.status,
      content.response.errorCode,
    );
  }

  return content.response;
};
