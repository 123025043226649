import { DAY_IN_MS, YEAR_IN_MS } from '../../constants/date';
import type { I18nOneDiscoveryFunction } from '../../lang';

export type Locales = 'fr' | 'pl' | 'en' | 'en-US' | 'it' | (string & {});

/**
 * isToday
 * @param date to check
 * @returns whether input date is today or not
 */
export const isToday = (date: Date): boolean => {
  const today = new Date(Date.now());

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

/**
 * isTomorrow
 * @param date to check
 * @returns whether input date is tomorrow or not
 */
export const isTomorrow = (date: Date): boolean => {
  const todayTimestamp = Date.now();
  const twoDaysAfter = new Date(todayTimestamp);

  // two days after today
  twoDaysAfter.setDate(twoDaysAfter.getDate() + 2);
  // two days after today at midnight
  twoDaysAfter.setHours(0, 0, 0, 0);

  const dateTimestamp = date.valueOf();

  return (
    !isToday(date) &&
    dateTimestamp > todayTimestamp &&
    dateTimestamp < twoDaysAfter.valueOf()
  );
};

/**
 * isYesterday
 * @param date to check
 * @returns whether input date is yesterday or not
 */
export const isYesterday = (date: Date): boolean => {
  const todayTimestamp = Date.now();
  const twoDaysBefore = new Date(todayTimestamp);

  // two days before today
  twoDaysBefore.setDate(twoDaysBefore.getDate() - 2);
  // two days before today right before the start of yesterday
  twoDaysBefore.setHours(23, 59, 59, 999);

  const dateTimestamp = date.valueOf();

  return (
    !isToday(date) &&
    dateTimestamp < todayTimestamp &&
    dateTimestamp > twoDaysBefore.valueOf()
  );
};

/**
 * isBefore
 * @param timestamp to check
 * @param timestampToCompare timestamp with which the first input is compared
 * @returns whether the first timestamp is before the second one
 */
export const isBefore = (
  timestamp: number,
  timestampToCompare: number,
): boolean => timestamp < timestampToCompare;

/**
 * isWithinInterval
 * @param timestamp to check
 * @param param1 a start and end timestamps representing the interval
 * @returns whether the first timestamp is between the start and end timestamps
 */
export const isWithinInterval = (
  timestamp: number,
  { start, end }: { start: number; end: number },
): boolean => timestamp >= start && timestamp <= end;

/**
 * isAfter
 * @param timestamp to check
 * @param timestampToCompare timestamp with which the first input is compared
 * @returns whether the first timestamp is after the second one
 */
export const isAfter = (
  timestamp: number,
  timestampToCompare: number,
): boolean => timestamp > timestampToCompare;

/**
 * Return correct date of day from timestamp
 *
 * @param [timestamp]  broadcasting date for the content
 * @param [langKey]    user's language
 * @returns a formatted date containing day and month on 2 digits
 * @example '01/01'
 */
export const getDateDay = (timestamp: number, langKey: Locales): string => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat(langKey, {
    day: '2-digit',
    month: '2-digit',
  }).format(date);
};

/**
 * Return correct complete date of day from timestamp
 *
 * @param [timestamp]  broadcasting date for the content
 * @param  [langKey]    user's language
 * @returns a formatted date containing week day + day number + month name + full year
 */
export const getCompleteDateDay = (
  timestamp: number,
  langKey: Locales,
): string => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat(langKey, {
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  }).format(date);
};

/**
 * Return correct date from millisecond timestamp
 *
 * @param [t]  translate function
 * @param [timestamp]  broadcasting date for the content
 * @param [langKey]    user's language
 * @param [isLabel]    whether the date is displayed in a card label
 * @returns formattedDate
 */
export const dateFormat = (
  t: I18nOneDiscoveryFunction,
  timestamp: number,
  langKey: Locales,
  isLabel = false,
): string => {
  if (!timestamp) {
    return '';
  }
  let dateInfos = '';

  // Get content broadcast infos
  const broadcastDate = new Date(timestamp);
  const broadcastHours = new Intl.DateTimeFormat(langKey, {
    hour: '2-digit',
    hourCycle: 'h23',
  })
    .format(broadcastDate)
    .substring(0, 2);
  const broadcastMinutes = new Intl.DateTimeFormat(langKey, {
    minute: '2-digit',
  })
    .format(broadcastDate)
    /** if one digit number, then convert to 2 digits */
    .replace(/^\d$/, (match) => `0${match}`);
  const futureBroadcast = new Intl.DateTimeFormat(langKey, {
    weekday: 'long',
    month: 'long',
    day: '2-digit',
  }).format(broadcastDate);
  const futureBroadcastJ2 = new Intl.DateTimeFormat(langKey, {
    weekday: 'short',
  }).format(broadcastDate);
  const futureBroadcastJ7 = new Intl.DateTimeFormat(langKey, {
    day: '2-digit',
    month: 'short',
  }).format(broadcastDate);

  // if broadcast is today
  if (isToday(broadcastDate)) {
    dateInfos = t(
      isLabel ? 'Date.broadcastTodayLabel' : 'Date.broadcastToday',
      {
        hours: broadcastHours,
        minutes: broadcastMinutes,
      },
    );

    // if broadcast is tomorrow
  } else if (isTomorrow(broadcastDate)) {
    dateInfos = t(
      isLabel ? 'Date.broadcastTomorrowLabel' : 'Date.broadcastTomorrow',
      {
        hours: broadcastHours,
        minutes: broadcastMinutes,
      },
    );

    // if broadcast is yesterday
  } else if (isYesterday(broadcastDate)) {
    dateInfos = t('Date.broadcastYesterday', {
      hours: broadcastHours,
      minutes: broadcastMinutes,
    });
  } else if (isLabel) {
    const now = new Date();
    const nowAtMidnight = now.setHours(24, 0, 0, 0);
    // add 1 day to now at midnight
    const j2 = new Date(nowAtMidnight);
    j2.setDate(j2.getDate() + 1);
    // add 5 days to now at midnight
    const j6 = new Date(nowAtMidnight);
    j6.setDate(j6.getDate() + 5);

    // if broadcast is between j+2 and j+6
    if (broadcastDate >= j2 && broadcastDate <= j6) {
      const dateInfosLower = t('Date.broadcastFutureLabel', {
        date: futureBroadcastJ2,
        hours: broadcastHours,
        minutes: broadcastMinutes,
      });
      dateInfos =
        dateInfosLower.charAt(0).toUpperCase() + dateInfosLower.slice(1);
    }

    // if broadcast is after j+6 (j+7 and after)
    else if (broadcastDate > j6) {
      dateInfos = t('Date.broadcastFutureLabel', {
        date: futureBroadcastJ7,
        hours: broadcastHours,
        minutes: broadcastMinutes,
      });
    }

    // all other cases
  } else {
    dateInfos = t('Date.broadcastFuture', {
      date: futureBroadcast,
      hours: broadcastHours,
      minutes: broadcastMinutes,
    });
  }

  return dateInfos;
};

/**
 *
 * @param timestamp  timestamp to compare
 * @returns whether the timestamp is at least one year from now or not
 */
export const isAtLeastOneYearFromNow = (timestamp: number): boolean => {
  const now = Date.now();
  return timestamp - now >= YEAR_IN_MS;
};

/**
 * daysBetweenDates
 *
 * method that format correctly a timestamp
 * to be displayed as an end of availability date
 *
 * @param  close closest date as timestamp
 * @param  far furthest date as timestamp
 * @returns number of days between input params
 */
export const daysBetweenDates = (close: number, far: number): number => {
  const closeDate: number = new Date(
    typeof close === 'number' ? close : close,
  ).getTime();
  const farDate: number = new Date(
    typeof far === 'number' ? far : far,
  ).getTime();
  return Math.abs(Math.ceil((closeDate - farDate) / DAY_IN_MS));
};

/**
 * getCurrentTimestamp
 *
 * @returns the current date in timestamp format in milliseconds
 */
export const getCurrentTimestamp = (): number => Math.floor(Date.now());
