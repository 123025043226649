import {
  type CreateTokenParameters,
  type PassUserDataEnriched,
} from '@canalplus/sdk-pass';
import { performCreateToken } from '../../services/Pass/performCreateToken';
import type { IState } from '../../store/types/State-type';
import { setDeviceIdCookie } from '../cookie/setDeviceIdCookie';
import { setSessionIdCookie } from '../cookie/setSessionIdCookie';
import { refreshProfileOneCore } from '../ifcOneCore/ifc-onecore-helpers';
import { getPassIdCookie } from '../pass/getPassIdCookie';
import { getPassSdkConfig } from '../pass/getPassSdkConfig';
import { setPassJSON } from '../pass/setPassJSON';

/**
 * - On TV, retrieves the user information from the R7 API by calling `refreshProfileOneCore`
 * - On browser, performs a full **Pass createToken** cinematic
 *   - Calls `createToken` and returns the enriched user data
 *   - Sets user Data in **window.passJSON**
 *   - Sets the session ID cookie if needed
 *   - Sets the device ID cookie if needed
 */
export const getClientSideUserInfos = async (
  state: IState,
  createTokenParameters?: CreateTokenParameters,
): Promise<PassUserDataEnriched> => {
  if ($_BUILD_RENDERMODE_CSR) {
    return refreshProfileOneCore({ noCache: createTokenParameters?.noCache });
  }

  const passSdkConfig = getPassSdkConfig({ state });

  const { passId, passToken, userData } = await performCreateToken(
    passSdkConfig,
    {
      ...createTokenParameters,
      passId: createTokenParameters?.passId || getPassIdCookie(),
    },
  );

  setPassJSON({
    ...userData,
    passToken,
  });

  setSessionIdCookie();
  setDeviceIdCookie(userData);

  return {
    ...userData,
    passToken,
    passId,
  };
};
