import { TimeoutError } from '@dce-front/util-fetch';
import { PassError } from '../../errors/PassError';

export const getErrorStatusCode = (error: unknown): number => {
  if (error instanceof PassError) {
    return error.statusCode;
  }

  if (error instanceof TimeoutError) {
    return 0;
  }

  return -1;
};
