import {
  setLocalStorageSettings,
  getLocalStorageSettings,
} from '../../helpers/settings/settings-helper';
import { userChangeSettings } from '../slices/user';

/** Handles all side effects for settings */
export const settingsMiddleware: Redux.Middleware =
  () => (next) => (action) => {
    if (userChangeSettings.match(action)) {
      const localSettings = getLocalStorageSettings();

      setLocalStorageSettings({
        ...action,
        payload: {
          ...localSettings,
          ...action.payload,
        },
      });
    }

    return next(action);
  };
