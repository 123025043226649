import type { Request } from 'express';

/**
 * Test if a request is a debug request
 *
 * @see https://gitlab.canalplus.pro/deddev/dedmycanalwebapp/-/issues/8398
 *
 * @param request Express request to test
 * @returns True if the request is considered as a debug request
 */
export function isDebugRequest(request: Request): boolean {
  return request.headers['xx-debug'] === '0';
}
