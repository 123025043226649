import { createSelector } from '@reduxjs/toolkit';
import type { ErrorState } from '../../store/slices/error';
import type { IState } from '../../store/types/State-type';

const errorSelector = (state: IState): ErrorState => state?.error;

export const errorTemplateTitleSelector = createSelector(
  errorSelector,
  (error) => error?.template?.title,
);

export const userSettingsErrorSelector = createSelector(
  errorSelector,
  (error) => error?.settings,
);

export const errorTrackingSelector = createSelector(
  errorSelector,
  (error) => error?.template?.tracking,
);

export const errorTemplateTextSelector = createSelector(
  errorSelector,
  (error) => error?.template?.text,
);

export const templateStatusSelector = createSelector(
  errorSelector,
  (error) => error?.template?.status ?? 200,
);
