import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { errorTemplate } from './error';

type Gradient = {
  color: string;
  position: string;
};

type PropertyGradient = {
  gradiant: Gradient[]; // Note: "gradient" is spelled incorrectly in the response ("gradient" = 👍)
};

// Structure of style object: https://canal-wiki.canal-plus.com/display/2M/Gestion+des+attributs+de+currentPage+et+style
type Style = {
  textColor?: string;
  background?: string | PropertyGradient;
};

export type Page = {
  mainOnClick?: Routing.IOneDiscoveryOnClick;
  subOnClick?: Routing.IOneDiscoveryOnClick;
  hasCover?: boolean;
  isFetching?: boolean;

  /** Whether it's an purchaseCode content or not */
  purchaseCode?: boolean;

  redirectUrl?: string;
  refetchPerso?: boolean;

  /** Contains information on the template style */
  style?: Style;

  tracking?: Tracking | ApiV2PageTracking;
  trackingContext?: ApiV2Context;
  uniqueId?: string;
};

export const initialState = {
  mainOnClick: {
    displayName: '',
    displayTemplate: '',
    path: '',
  },
  refetchPerso: false,
  style: {},
} satisfies Page as Page;

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    requestTemplate(_state, action: PayloadAction<Page>) {
      return action.payload;
    },
    refetchPerso(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        refetchPerso: action.payload,
      };
    },
    updatePageTracking(
      state,
      action: PayloadAction<Tracking | ApiV2PageTracking>,
    ) {
      return {
        ...state,
        tracking: action.payload,
      };
    },
    hasPageCover(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasCover: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(errorTemplate, (state, action) => {
      const { displayTemplate, displayName, path, immersive } = action.payload;

      // Do not update page state if immersive mode is active
      if (immersive) {
        return { ...state };
      }

      return {
        ...state,
        mainOnClick: {
          displayTemplate,
          displayName,
          path,
        },
      };
    });
  },
});

export const {
  requestTemplate,
  refetchPerso,
  updatePageTracking,
  hasPageCover,
} = pageSlice.actions;

export const pageReducer = pageSlice.reducer;

export type PageActions = ReturnType<
  (typeof pageSlice.actions)[keyof typeof pageSlice.actions]
>;
