import type { ApiV2InitResponse } from '@dce-front/hodor-types';
import { setApplicationInitTracking } from '../../../../store/slices/application';

type SetInitTrackingParameters = {
  init: ApiV2InitResponse;
  store: Redux.CustomStore;
};

/** this function is used to store hodor init tracking
 * @param {Object} store - the redux store
 * @param {Object} init - the init response from hodor
 * ⚠️ To be performed Client Side and Server side
 */
export function setInitTracking({
  store,
  init,
}: SetInitTrackingParameters): void {
  const { tracking } = init || {};

  if (tracking) {
    store.dispatch(setApplicationInitTracking(tracking));
  }
}
