import { ResizeMode } from '@dce-front/onewebapp-utils';

const WIDTH_720P = 1280;

export const getResizeModeAuto = (): string => {
  // check size viewport
  // case 720p
  if (window.innerWidth <= WIDTH_720P) {
    return ResizeMode.RESIZE_720P;
  }

  return '';
};
