import { ALL_EXCEPT_AMPERSAND_PATTERN } from '../regex/constants';
import { getQsValueByKey } from './getQsValueByKey';

/**
 * URL Helper
 * --------------------
 *
 * Add a **query parameter** in an url
 *
 * @param url The **url** to parse
 * @param name The **name** of the query parameter to add
 * @param value The **value** of the query parameter to add
 * @return Return a new url with the QP added
 *
 * @category Url
 * @example
 * addQueryParam('https://url.com', 'name', 'value')
 * // => 'https://url.com?name=value'
 */
export const addQueryParam = (
  url: string,
  name: string,
  value: string,
): string => {
  const isFirstQP = url.split('?').length !== 2;
  const separator = isFirstQP ? '?' : '&';
  const isQPAlreadyPresent = Boolean(getQsValueByKey(url, name));

  // If the query parameter is already present, we're updating the value
  if (isQPAlreadyPresent) {
    const searchValue = new RegExp(
      `${name}=${ALL_EXCEPT_AMPERSAND_PATTERN}`,
      'gi',
    );

    return url.replace(searchValue, `${name}=${value}`);
  }

  return `${url}${separator}${name}=${value}`;
};
