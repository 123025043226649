import { getPublicConfig } from '../../helpers/config/config-helper';

export const rewriteUrlCallback = (url: string): string => {
  const { proxificationList } = getPublicConfig();

  if (!proxificationList) {
    return url;
  }

  const { to } =
    proxificationList.find((item) =>
      item.from.includes('https://hodor.canalplus.pro'),
    ) || {};

  if (!to) {
    return url;
  }

  return url.replace('https://hodor.canalplus.pro/api/v2/', to);
};
