/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getItem", "clear", "length", "key"] }] */
const storage = new Map<string, string>();

/**
 * In-memory mock for localStorage / sessionStorage
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 */
export default class StorageMock implements Storage {
  constructor() {
    // define storage as non-enumerable to match native Storage API
    Object.defineProperty(this, 'storage', {
      enumerable: false,
      value: new Map<string, string>(),
    });
  }

  get length(): number {
    return storage.size;
  }

  setItem(key: string, value: string): void {
    storage.set(key, value);
    // @ts-expect-error weird native api behavior necessary to emulate Object.keys(storage)
    this[key] = value;
  }

  getItem(key: string): string | null {
    return storage.get(key) ?? null;
  }

  removeItem(key: string): void {
    // @ts-expect-error
    delete this[key];
    storage.delete(key);
  }

  clear(): void {
    storage.clear();
  }

  key(index: number): string | null {
    const keys = Array.from(storage.keys());
    return keys[index] ?? null;
  }
}
