import { Template } from '@canalplus/sdk-hodor';
import { getPublicConfig } from '../config/config-helper';

/**
 * isHapi
 *
 * Checks if the platform returned by WS is HAPI
 *
 * @param  {string}  [platform=''] platform type
 * @returns {bool}               true if platform is HAPI
 */
export const isHapi = (platform: string = ''): boolean =>
  platform.toLowerCase() === getPublicConfig().PLAYER.PLATFORM.HAPI;

/**
 * isDRMFreeContent
 *
 * Check if video is DRM free
 *
 * @param  {string}  [consumptionPlatform='']  platform type
 * @returns {bool}                              true if DRM free
 */
export const isDRMFreeContent = (consumptionPlatform: string = ''): boolean =>
  consumptionPlatform.toLowerCase() === getPublicConfig().PLAYER.PLATFORM.PFV;

/**
 * isLiveContent
 *
 * Check if video is a live content
 *
 * @param  {string}  [consumptionPlatform='']  platform type
 * @returns {bool}                              true if live platform
 */
export const isLiveContent = (consumptionPlatform: string = ''): boolean =>
  consumptionPlatform.toLowerCase() === getPublicConfig().PLAYER.PLATFORM.LIVE;

/**
 * Check if the content is VOD ready
 *
 * @param {bool} [availableInVoDOnDevice]    platform used to play media
 * @param {string}   [consumptionPlatform] whether the media is available on current device
 * @param {string} [contentID]              media's id
 * @returns {bool}                           true if the media is ready for VoD
 */
export const isReadyForVoD = (
  availableInVoDOnDevice: boolean,
  consumptionPlatform: string,
  contentID: string,
): boolean =>
  Boolean(availableInVoDOnDevice && consumptionPlatform && contentID);

/**
 * Check if the content is available to download
 *
 * @param {bool}   [isMobile]               if we are in a mobile context
 * @param {string} [displayTemplate]        current page template
 * @param {string} [consumptionPlatform]    platform used to play media
 * @param {bool}   [availableInVoDOnDevice] whether the media is available on current device
 * @param {string} [contentID]              media's id
 * @param {bool}   [isProspect]             whether the user is prospect or not (abonne...)
 *
 * @returns {bool}                           true if the media is downloadable
 */

export interface IShowDownloadToGo {
  readonly isMobile: boolean;
  readonly displayTemplate: string;
  readonly consumptionPlatform: string;
  readonly isStreamAvailable: boolean;
  readonly contentID: string;
  readonly isDownloadAvailable: boolean;
}

export const showDownloadToGo = ({
  isMobile,
  displayTemplate,
  consumptionPlatform,
  isStreamAvailable,
  contentID,
  isDownloadAvailable,
}: IShowDownloadToGo): boolean => {
  const publicConfig = getPublicConfig();
  const notAvailable =
    isMobile ||
    displayTemplate === Template.DetailSeason ||
    (consumptionPlatform &&
      consumptionPlatform.toLowerCase() === publicConfig.PLAYER.PLATFORM.PFV) ||
    !isReadyForVoD(isStreamAvailable, consumptionPlatform, contentID);

  return isDownloadAvailable && !notAvailable;
};

const RE_UA =
  /([Cc]hr[o0O]me\/(?<chromeVersion>\d+)|PlayStation (?<playStationVersion>\d)|(?<canalLegacyBox>Canal-STB))|Tizen\s+(?<tizenVersion>[\d.]+)/;

const MINIMUM_ES6_VERSION = {
  chromeVersion: 68,
  playStationVersion: 5,
  tizenVersion: 5.5,
  canalLegacyBox: Number.MAX_SAFE_INTEGER,
};

/**
 * Test a user agent to check if this is considered as an es5 platform
 * @param useragent User-agent to test
 * @returns True or false
 *
 * @see https://canal-wiki.canal-plus.com/pages/viewpage.action?spaceKey=DFER&title=STB+Tech+-+Browsers
 */
export const isEs5Platform = (useragent?: string): boolean => {
  if (!useragent) {
    return false;
  }

  const match = useragent.match(RE_UA);

  if (match) {
    return !!Object.entries(MINIMUM_ES6_VERSION).find(
      ([key, version]) =>
        match?.groups?.[key] &&
        (version === Number.MAX_SAFE_INTEGER ||
          parseFloat(match.groups[key]) < version),
    );
  }

  return false;
};
