import { createSelector } from '@reduxjs/toolkit';
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_MOBILE_LABEL,
} from '../../constants/responsive';
import { getBreakpointLabel } from '../../helpers/responsive/responsive-helper';
import type { UiState } from '../../store/slices/ui';
import type { IState } from '../../store/types/State-type';
import { isClientRenderSourceSelector } from './application-selectors';

const uiSelector = (state: IState): UiState => state?.ui;

export const screenWidthSelector = createSelector(
  uiSelector,
  (ui) => ui?.screenWidth,
);

export const currentBreakpointSelector = createSelector(
  screenWidthSelector,
  (screenWidth) => getBreakpointLabel(screenWidth),
);

export const isPhoneResolutionSelector = createSelector(
  currentBreakpointSelector,
  isClientRenderSourceSelector,
  (breakpoint, isClientRenderSource) =>
    isClientRenderSource && breakpoint === BREAKPOINT_MOBILE_LABEL,
);

// "mobile" refers to cell phones and tablets, thus any device smaller than laptop resolution
export const isMobileResolutionSelector = createSelector(
  screenWidthSelector,
  isClientRenderSourceSelector,
  (resolution, isClientRenderSource) =>
    isClientRenderSource && resolution < BREAKPOINT_LAPTOP,
);

export const noScrollSelector = createSelector(
  uiSelector,
  (ui) => ui?.noScroll,
);

export const displayModeSelector = createSelector(
  uiSelector,
  (ui) => ui?.displayMode,
);

export const showSummaryEpisodesSelector = createSelector(
  uiSelector,
  (ui) => ui?.showSummaryEpisodes,
);

export const isEmbeddedVideoFullscreenOpenedSelector = createSelector(
  uiSelector,
  (ui) => ui?.isEmbeddedVideoFullscreenOpened,
);
