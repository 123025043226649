import { isClientSide } from '@dce-front/onewebapp-utils';
import { UAParser } from 'ua-parser-js';

/**
 * Mobile Detection
 *
 * In charge to return
 * the device by comparing patterns against a given User-Agent string.
 */

const parseUserAgent = (userAgent?: string): UAParser.IResult | undefined => {
  // Server side, if no userAgent is provided:
  if (!userAgent && !isClientSide()) {
    return undefined;
  }
  return new UAParser(userAgent || window?.navigator?.userAgent).getResult();
};

const getBrowser = (userAgent?: string): string | undefined => {
  const parsedUA = parseUserAgent(userAgent);
  return parsedUA?.browser?.name;
};

const getOS = (userAgent?: string): string | undefined => {
  const parsedUA = parseUserAgent(userAgent);
  return parsedUA?.os?.name;
};

/** Is it an Android mobile browser ? */
const isAndroid = (userAgent?: string): boolean => {
  return (getOS(userAgent) || '').startsWith('Android');
};

/** Is it an ios mobile browser ? */
const isIOS = (userAgent?: string): boolean => {
  return (getOS(userAgent) || '').startsWith('iOS');
};

/** Is it an Windows Phone mobile browser ? */
const isWindowsPhone = (userAgent?: string): boolean =>
  (getOS(userAgent) || '').startsWith('Windows Phone') ||
  (getOS(userAgent) || '').startsWith('Windows Mobile');

/** Is it a mobile browser ? */
const isAnyMobile = (userAgent?: string): boolean =>
  isAndroid(userAgent) || isIOS(userAgent) || isWindowsPhone(userAgent);

/** Is it a Windows Desktop browser ? */
export const isWindows = (userAgent?: string): boolean =>
  getOS(userAgent) === 'Windows';

/** Is it a MacOS browser ? */
export const isMacOs = (userAgent?: string): boolean =>
  (getOS(userAgent) || '').startsWith('Mac OS');

/** Is it a Desktop browser ? */
const isAnyDesktop = (userAgent?: string): boolean =>
  isWindows(userAgent) || isMacOs(userAgent);

/** Is the browser Safari ? */
const isSafari = (userAgent?: string): boolean =>
  (getBrowser(userAgent) || '').includes('Safari'); // "Safari" or "Mobile Safari"

type UserAgent = {
  isBrowser: {
    isSafari: boolean;
  };
  isDesktop: {
    isAny: boolean;
    isMacOs: boolean;
    isWindows: boolean;
  };
  isMobile: {
    isAndroid: boolean;
    isAny: boolean;
    isIOS: boolean;
    isWindows: boolean;
  };
};

/**
 * Parse user agent and return a UserAgent object
 */
export const getUserAgent = (userAgent?: string): UserAgent => ({
  isBrowser: {
    isSafari: isSafari(userAgent),
  },
  isDesktop: {
    isAny: isAnyDesktop(userAgent),
    isMacOs: isMacOs(userAgent),
    isWindows: isWindows(userAgent),
  },
  isMobile: {
    isAndroid: isAndroid(userAgent),
    isAny: isAnyMobile(userAgent),
    isIOS: isIOS(userAgent),
    isWindows: isWindowsPhone(userAgent),
  },
});

/**
 * Compare current osVersion with given version
 * Returns true if currentOsVersion>=version, false otherwise
 * @param  {string} version OS version
 * @returns {number}
 */
export const isNewerVersion = (version: string): boolean => {
  if (typeof version !== 'string') {
    throw new Error('Parameter must be of type string');
  }

  const { version: currOsVersion } = new UAParser(
    window.navigator.userAgent,
  ).getOS();

  if (!currOsVersion) {
    return false;
  }

  const currOsVersionArr = currOsVersion.split('.').map(Number);
  const versionArr = version.split('.').map(Number);
  const k = Math.min(currOsVersionArr.length, versionArr.length);

  for (let i = 0; i < k; i += 1) {
    if ((currOsVersionArr[i] || 0) > (versionArr[i] || 0)) {
      return true;
    }

    if ((currOsVersionArr[i] || 0) < (versionArr[i] || 0)) {
      return false;
    }
  }

  if (currOsVersionArr.length === versionArr.length) {
    return true;
  } else if (currOsVersionArr.length < versionArr.length) {
    return false;
  }

  return true;
};
