import { removeCookie, setCookie } from '@dce-front/onewebapp-utils';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { getCookieOptions } from '../cookie/getCookieOptions';

/**
 * WhoIsWatching session expire in minutes
 */
const WHO_IS_WATCHING_SESSION_EXPIRE = 30;

export const getCookieWhoIsWatchingDisabled = (): boolean => {
  return Cookies.get(CookieKey.WhoIsWatchingDisabled) === 'true';
};

export const getCookieWhoIsWatchingSession = (): boolean => {
  return Cookies.get(CookieKey.WhoIsWatchingSession) === 'true';
};

export const setCookieWhoIsWatchingDisabled = (disabled: boolean): void =>
  setCookie(
    CookieKey.WhoIsWatchingDisabled,
    disabled.toString(),
    getCookieOptions({
      sameSite: $_BUILD_RENDERMODE_CSR ? 'none' : 'Lax',
    }),
  );

export const setCookieWhoIsWatchingSession = (): void =>
  setCookie(
    CookieKey.WhoIsWatchingSession,
    'true',
    getCookieOptions({
      sameSite: $_BUILD_RENDERMODE_CSR ? 'none' : 'Lax',
      expires: new Date(
        Date.now() + WHO_IS_WATCHING_SESSION_EXPIRE * 60 * 1000,
      ), // expire in 30 minutes,
    }),
  );

export const removeCookieWhoIsWatchingSession = (): void => {
  removeCookie(CookieKey.WhoIsWatchingSession);
};

export const removeAllCookiesWhoIsWatching = (): void => {
  removeCookie(CookieKey.WhoIsWatchingDisabled);
  removeCookie(CookieKey.WhoIsWatchingSession);
};
