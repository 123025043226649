import type { NumberStringObject, StringMap } from '../types';

/**
 * Returns StringMap from an object containing string and/or number values
 *
 * @category Function
 *
 * @example
 * const obj = {
 *  a: '1',
 *  b: 2
 * }
 *
 * getStringMapFromNumberStringObject(obj) // return [['a', '1'], ['b', '2']];
 */
export const getStringMapFromNumberStringObject = (
  queries: NumberStringObject,
): StringMap =>
  Object.entries(queries).map(([key, value]) => [key, value.toString()]);
