import { makeLogger } from '@dce-front/util-logger';
import { getPublicConfig } from '../config/config-helper';

let logger: ReturnType<typeof makeLogger>;

if ($_BUILD_RENDERMODE_SSR) {
  const { client, server } = getPublicConfig().log;

  logger = makeLogger(IS_SERVER ? server : client);
} else {
  logger = makeLogger({
    isActive: true,
    level: 'info',
    format: 'json',
  });
}

export default logger;
