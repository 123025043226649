export const LANGUAGES = {
  'de-ch': 'de-ch', // Suisse alémanique
  'en-et': 'en-us', // Ethiopie
  'en-us': 'en-us', // Etats-Unis
  'fr-au': 'fr-au', // Australie
  'fr-bf': 'fr-afr', // Burkina Faso
  'fr-bi': 'fr-afr', // Burundi
  'fr-bj': 'fr-afr', // Bénin
  'fr-bl': 'fr-drom', // Saint-Barthélemy
  'fr-cd': 'fr-afr', // République démocratique du Congo
  'fr-cf': 'fr-afr', // République centrafricaine
  'fr-cg': 'fr-afr', // Congo
  'fr-ch': 'fr-ch', // Suisse
  'fr-ci': 'fr-afr', // Côte d’Ivoire
  'fr-cm': 'fr-afr', // Cameroun
  'fr-cv': 'fr-afr', // Cap Vert
  'fr-dj': 'fr-afr', // Djibouti
  'fr-fr': 'fr-fr', // France
  'fr-ga': 'fr-afr', // Gabon
  'fr-gh': 'fr-afr', // Ghana
  'fr-gm': 'fr-afr', // Gambie
  'fr-gn': 'fr-afr', // Guinée Conakry
  'fr-gp': 'fr-drom', // Guadeloupe
  'fr-gq': 'fr-afr', // Guinée Équatoriale
  'fr-gw': 'fr-afr', // Guinée Bissau
  'fr-km': 'fr-km', // Comores
  'fr-mf': 'fr-drom', // Saint-Martin
  'fr-mg': 'fr-afr', // Madagascar
  'fr-ml': 'fr-afr', // Mali
  'fr-mq': 'fr-drom', // Martinique
  'fr-mr': 'fr-afr', // Mauritanie
  'fr-nc': 'fr-nc', // Nouvelle-Calédonie
  'fr-ne': 'fr-afr', // Niger
  'fr-ng': 'fr-afr', // Nigeria
  'fr-pf': 'fr-drom', // French Polynesia
  'fr-re': 'fr-drom', // La Réunion
  'fr-rw': 'fr-afr', // Rwanda
  'fr-sl': 'fr-afr', // Sierra Leone
  'fr-sn': 'fr-afr', // Sénégal
  'fr-td': 'fr-afr', // Tchad
  'fr-tg': 'fr-afr', // Togo
  'fr-yt': 'fr-drom', // Mayotte
  'it-it': 'it-it', // Italia
  'pl-pl': 'pl-pl', // Poland
} as const;

export type Locale = keyof typeof LANGUAGES;
